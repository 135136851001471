<template>
  <div align="left" class="mt-2">
    <ServiceDialog
      v-if="openDialog"
      :openDialog="openDialog"
      :serviceDetails="activeItem"
      primaryColor="teal"
      @closeServiceDialog="closeServiceDialog"
    />
    <v-card color="teal darken-1">
      <div align="center">
        <v-icon size="60" class="no-italics" color="teal darken-4"
          >tz-bi-kopanong</v-icon
        >
        <h2 class="teal--text text--darken-4">Hosting Services</h2>
      </div>
      <v-card class="mx-auto" tile id="cloud-services">
        <v-list dense color="teal lighten-1">
          <h2 class="teal--text text--darken-4 ml-3">Providers</h2>
          <v-list-item-group v-model="selectedItem" color="blue darken-4">
            <v-list-item
              v-for="(item, i) in hostingServices.providers"
              @click="viewServiceDetails(item)"
              :key="i"
            >
              <v-list-item-icon>
                <v-icon
                  color="teal darken-4"
                  size="35"
                  style="font-style: normal; font-weight: 500;"
                  >{{ item.icon }}</v-icon
                >
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  class="black--text text--darken-4"
                  v-text="item.title"
                ></v-list-item-title>
                <v-list-item-subtitle v-text="item.description">
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
          <h2 class="teal--text text--darken-4 ml-3">Solutions</h2>
          <v-list-item-group color="deep-orange accent-4">
            <v-list-item
              v-for="(item, i) in hostingServices.solutions"
              @click="viewServiceDetails(item)"
              :key="i"
            >
              <v-list-item-icon>
                <v-icon
                  color="teal darken-4"
                  size="35"
                  style="font-style: normal; font-weight: 500;"
                  >{{ item.icon }}</v-icon
                >
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  class="black--text text--darken-4"
                  v-text="item.title"
                ></v-list-item-title>
                <v-list-item-subtitle v-text="item.description">
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-card>
  </div>
</template>

<script>
import ServiceDialog from "@/components/ServiceDialog.vue";
export default {
  components: { ServiceDialog },
  props: ["hostingServices"],
  data() {
    return {
      activeItem: null,
      selectedItem: 0,
      openDialog: false,
    };
  },
  methods: {
    viewServiceDetails(service) {
      this.activeItem = service;
      this.openDialog = true;
    },
    closeServiceDialog() {
      this.openDialog = false;
    },
  },
};
</script>
