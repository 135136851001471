<template>
  <main>
    <div align="center">
      <BrandStrategy :brandingStrategies="brandingStrategies" />
      <HostingServices :hostingServices="hostingServices" />
      <DistributionServices :distributionServices="distributionServices" />
    </div>
  </main>
</template>

<script>
import BrandStrategy from "@/components/services/BrandStrategy";
import HostingServices from "@/components/services/HostingServices";
import DistributionServices from "@/components/services/DistributionServices";

export default {
  title: "BA - Services",
  computed: {
    brandingStrategies() {
      return this.$store.getters.brandingStrategies;
    },
    hostingServices() {
      return this.$store.getters.hostingServices;
    },
    distributionServices() {
      return this.$store.getters.distributionServices;
    },
  },
  components: {
    BrandStrategy,
    HostingServices,
    DistributionServices,
  },
};
</script>
