<template>
  <div style="text-align: center;">
    <div style="margin: 0 auto;">
      <v-dialog v-model="dialog" persistent max-width="290">
        <v-card
          :color="primaryColor + ' lighten-1'"
          height="100%"
          style="margin: 0 auto;"
        >
          <div align="center">
            <v-icon color="teal darken-4" size="200" class="no-italics">
              {{ serviceDetails.icon }}
            </v-icon>

            <v-layout class="ma-2" wrap column :row="false">
              <h1 :class="primaryColor + '--text text--darken-4'">
                {{ serviceDetails.title }}
              </h1>
              <p class="teal--text text--darken-4">
                {{ serviceDetails.description }}
              </p>
            </v-layout>

            <v-card-actions>
              <div class="join-us">
                <v-btn
                  :class="primaryColor + ' darken-4 white--text mb-3'"
                  rounded
                  large
                  @click="closeDialog"
                >
                  Close
                </v-btn>
              </div>
            </v-card-actions>
          </div>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    openDialog: {
      type: Boolean,
      default: false,
    },
    serviceDetails: {
      type: Object,
      required: true,
    },
    primaryColor: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  mounted() {
    this.dialog = this.openDialog;
  },
  methods: {
    closeDialog() {
      this.dialog = false;
      this.$emit("closeServiceDialog", null);
    },
  },
};
</script>
